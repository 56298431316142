import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    paymentTypeOptions: [],
  },
  getters: {},
  mutations: {
    UPDATE_PAYMENT_TYPE_LIST_STATE(state, val) {
      state.paymentTypeOptions = val
    },
  },
  actions: {
    // ----------------------金流商分類管理----------------------
    // (查詢)金流商分類列表
    getPaymentTypeList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/thirdPartyPayment', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)金流商分類
    getPaymentType(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/thirdPartyPayment/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)金流商分類
    setPaymentTypeCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/thirdPartyPayment', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)金流商分類
    setPaymentTypeUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/thirdPartyPayment/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // ----------------------金流商管理----------------------
    // (查詢)金流
    getPaymentList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/payment', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)金流
    getPaymentData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/payment/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)金流
    setPaymentCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/payment', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (測試)金流
    setPaymentTest(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/payment/${resolveData.id}/test`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)金流
    setPaymentUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/payment/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
