import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    domainAgentOptions: [],
    isBusyLoading: true,
    domainPriceInfo: null,
  },
  getters: {},
  mutations: {
    UPDATE_DOMAIN_AGENT_LIST_STATE(state, val) {
      state.domainAgentOptions = val
    },
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_DOMAIN_PRICE_INFO_STATE(state, val) {
      state.domainPriceInfo = val
    },
  },
  actions: {
    // ----------------------網域商分類管理----------------------
    // (查詢)網域商列表
    getDomainAgentList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainNameAgent', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一網域商
    getDomainAgent(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainNameAgent/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)網域商
    setDomainAgentCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/domainNameAgent', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)網域商
    setDomainAgentUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainNameAgent/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ----------------------經銷商分類管理----------------------
    // (查詢)經銷商列表
    getDomainDealerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainNameDealer', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一經銷商
    getDomainDealer(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainNameDealer/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)經銷商
    setDomainDealerCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/domainNameDealer', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)經銷商
    setDomainDealerUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainNameDealer/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)經銷商
    setDomainAgentDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainNameDealer/${resolveData.id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ----------------------網域售價管理----------------------
    // (查詢)網域商品列表
    getDomainListData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainNamePrice', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一網域商品
    getDomainData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainNamePrice/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一網域商品售價
    getDomainPriceData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainNamePrice/${resolveData.id}/price`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)網域商品
    setDomainCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/domainNamePrice', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)網域商品
    setDomainUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainNamePrice/${resolveData.id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)網域商品
    setDomainDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainNamePrice/${resolveData.id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------------------------------------------------------
    // (更新)網域成本
    getDomainDealerCost(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/cost/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)經銷商餘額
    getDomainDealerBalance(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainNameDealer/balance/${resolveData.id}`, { headers: { 'Access-Token': useJwt.getToken() } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
