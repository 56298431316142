import Vue from 'vue'
import Vuex from 'vuex'

import api from './api'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import adminApiMailModal from '@/views/admin/api/mail/useStoreModule'
import adminDomainModal from '@/views/admin/domain/useStoreModule'
import adminBranchModal from '@/views/admin/branch/useStoreModule'
import adminPaymentModal from '@/views/admin/payment/useStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    app,
    appConfig,
    verticalMenu,
    'admin-api-mail-modal': adminApiMailModal,
    'admin-domain-modal': adminDomainModal,
    'admin-branch-modal': adminBranchModal,
    'admin-payment-modal': adminPaymentModal,
  },
  strict: process.env.DEV,
})
