export default [
  // -------------------------------服務管理---------------------------------
  {
    path: '/admin/service/domain/:action',
    name: 'admin-service-domain',
    component: () => import('@/views/admin/service/domain/domain-list/DomainList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '網域列表',
      breadcrumb: [
        {
          text: '服務管理',
          active: true,
        },
        {
          text: '網域列表',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      // { name: 'admin-service-domain', params: { action: to.params.action } }
      if (['list', 'log'].includes(to.params.action)) {
        next()
      } else next({ name: 'admin-service-domain', params: { action: 'list' } })
    },
  },
  {
    path: '/admin/service/domain/view/:id',
    name: 'admin-service-domain-view',
    component: () => import('@/views/admin/service/domain/domain-view/DomainView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '網域詳情',
      breadcrumb: [
        {
          text: '服務管理',
          active: true,
        },
        {
          text: '網域列表',
          to: '/admin/service/domain/list',
        },
        {
          text: '網域詳情',
          active: true,
        },
      ],
    },
  },
  // -------------------------------API管理----------------------------------
  {
    path: '/admin/api/mail',
    name: 'admin-api-mail',
    component: () => import('@/views/admin/api/mail/mail-list/MailList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '信箱API列表',
      breadcrumb: [
        {
          text: 'API管理',
          active: true,
        },
        {
          text: '信箱API列表',
          active: true,
        },
      ],
    },
  },
  // -------------------------------訂單管理----------------------------------
  {
    path: '/admin/order',
    name: 'admin-order',
    component: () => import('@/views/admin/order/order-list/OrderList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '訂單列表',
      breadcrumb: [
        {
          text: '訂單列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/order/add',
    name: 'admin-order-add',
    component: () => import('@/views/admin/order/order-add/OrderAdd.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '訂單創建',
      breadcrumb: [
        {
          text: '訂單列表',
          to: '/admin/order',
        },
        {
          text: '訂單創建',
          active: true,
        },
      ],
      navActiveLink: 'admin-order',
      belong: 'admin-order',
    },
  },
  {
    path: '/admin/order/view/:id',
    name: 'admin-order-view',
    component: () => import('@/views/admin/order/order-view/OrderView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '訂單詳情',
      breadcrumb: [
        {
          text: '訂單列表',
          to: '/admin/order',
        },
        {
          text: '訂單詳情',
          active: true,
        },
      ],
      navActiveLink: 'admin-order',
      belong: 'admin-order',
    },
  },
  {
    path: '/admin/quota',
    name: 'admin-quota',
    component: () => import('@/views/admin/quota/quota-list/QuotaList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '點數紀錄',
      breadcrumb: [
        {
          text: '點數紀錄',
          active: true,
        },
      ],
    },
  },
  // -------------------------------購物車管理----------------------------------
  {
    path: '/admin/cart',
    name: 'admin-cart',
    component: () => import('@/views/admin/cart/cart-list/CartList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '購物車列表',
      breadcrumb: [
        {
          text: '購物車列表',
          active: true,
        },
      ],
    },
  },
  // -------------------------------網域管理----------------------------------
  {
    path: '/admin/domain/agent',
    name: 'admin-domain-agent',
    component: () => import('@/views/admin/domain/domain-agent/AgentList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '網域商列表',
      breadcrumb: [
        {
          text: '網域管理',
          active: true,
        },
        {
          text: '網域商列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/domain/dealer',
    name: 'admin-domain-dealer',
    component: () => import('@/views/admin/domain/domain-dealer/DealerList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '經銷商列表',
      breadcrumb: [
        {
          text: '網域管理',
          active: true,
        },
        {
          text: '經銷商列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/domain/price',
    name: 'admin-domain-price',
    component: () => import('@/views/admin/domain/domain-price/price-list/PriceList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '網域商品',
      breadcrumb: [
        {
          text: '網域管理',
          active: true,
        },
        {
          text: '網域商品',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/domain/price/setting/:id',
    name: 'admin-domain-price-setting',
    component: () => import('@/views/admin/domain/domain-price/price-setting/PriceSetting.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '價格設定',
      breadcrumb: [
        {
          text: '網域管理',
          active: true,
        },
        {
          text: '網域商品',
          to: '/admin/domain/price',
        },
        {
          text: '價格設定',
          active: true,
        },
      ],
      navActiveLink: 'admin-domain-price',
      belong: 'admin-domain-price',
    },
  },
  // -------------------------------金流列表----------------------------------
  {
    path: '/admin/payment',
    name: 'admin-payment',
    component: () => import('@/views/admin/payment/payment-list/PaymentList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '金流列表',
      breadcrumb: [
        {
          text: '金流列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/payment/type',
    name: 'admin-payment-type',
    component: () => import('@/views/admin/payment/payment-type/PaymentType.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '金流商管理',
      breadcrumb: [
        {
          text: '金流商管理',
          active: true,
        },
      ],
    },
  },
  // -------------------------------分站列表----------------------------------
  {
    path: '/admin/branch',
    name: 'admin-branch',
    component: () => import('@/views/admin/branch/branch-list/BranchList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '分站列表',
      breadcrumb: [
        {
          text: '分站列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/branch/:id',
    name: 'admin-branch-view',
    component: () => import('@/views/admin/branch/branch-view/BranchView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '分站列表',
      breadcrumb: [
        {
          text: '分站列表',
          to: '/admin/branch',
        },
        {
          text: '分站詳情',
          active: true,
        },
      ],
      navActiveLink: 'admin-branch',
      belong: 'admin-branch',
    },
  },
  // -------------------------------標籤列表----------------------------------
  {
    path: '/admin/tag',
    name: 'admin-tag',
    component: () => import('@/views/admin/tag/tag-list/TagList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '標籤列表',
      breadcrumb: [
        {
          text: '標籤列表',
          active: true,
        },
      ],
    },
  },
  // -------------------------------權限列表----------------------------------
  {
    path: '/admin/power',
    name: 'admin-power',
    component: () => import('@/views/admin/power/power-list/PowerList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '權限列表',
      breadcrumb: [
        {
          text: '權限列表',
          active: true,
        },
      ],
    },
  },
  // -------------------------------黑名單列表----------------------------------
  {
    path: '/admin/black',
    name: 'admin-black',
    component: () => import('@/views/admin/black/black-list/BlackList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '黑名單列表',
      breadcrumb: [
        {
          text: '黑名單列表',
          active: true,
        },
      ],
    },
  },
  // -------------------------------會員列表----------------------------------
  {
    path: '/admin/customer',
    name: 'admin-customer',
    component: () => import('@/views/admin/customer/customer-list/CustomerList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '會員列表',
      breadcrumb: [
        {
          text: '會員列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/customer/:id',
    name: 'admin-customer-view',
    component: () => import('@/views/admin/customer/customer-view/CustomerView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '會員列表',
      breadcrumb: [
        {
          text: '會員列表',
          to: '/admin/customer',
        },
        {
          text: '會員詳情',
          active: true,
        },
      ],
      navActiveLink: 'admin-customer',
      belong: 'admin-customer',
    },
  },
  // -------------------------------管理者列表列表----------------------------------
  {
    path: '/admin/user',
    name: 'admin-user',
    component: () => import('@/views/admin/user/user-list/UsersList.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '管理者列表',
      breadcrumb: [
        {
          text: '管理者列表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/user/:id',
    name: 'admin-user-view',
    component: () => import('@/views/admin/user/user-view/UserView.vue'),
    meta: {
      isAdmin: true,
      pageTitle: '管理者列表',
      breadcrumb: [
        {
          text: '管理者列表',
          to: '/admin/user',
        },
        {
          text: '管理者詳情',
          active: true,
        },
      ],
      navActiveLink: 'admin-user',
      belong: 'admin-user',
    },
  },
  // -------------------------------後台首頁----------------------------------
  {
    path: '/admin/home',
    name: 'admin-home',
    component: () => import('@/views/admin/home/Home.vue'),
    meta: {
      pageTitle: '後台首頁',
      breadcrumb: [
        {
          text: '後台首頁',
          active: true,
        },
      ],
    },
  },
]
