export default [
  // -------------------------------會員列表----------------------------------
  {
    path: '/admin/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: '個人資料',
      breadcrumb: [
        {
          text: '個人資料',
          active: true,
        },
      ],
    },
  },
]
